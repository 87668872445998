<template>
  <div>
    <div class="p-2 card">
      <b-overlay :show="loading.pagina" rounded="lg" opacity="0.7">
        <b-row class="margin-personalizada">
          <b-col md="12" align="center">
              <div class="container-custom-message">
                <div class="c-button-personalizado">
                  <b-button
                    type="submit"
                    variant="custom"
                    class="b-personalizado"
                    :disabled="true"
                  >
                    <b-img  :src="require('@/assets/custom-icons/mail.svg')" class="img-link" />
                  </b-button>
                </div>
                <div class="c-message-personalizado">
                  <span class="ml-25 align-middle">
                    Envio das informações de acesso (Documento com QR Code para impressão e link para a Cora)
                  </span>
                </div>
              </div>
          </b-col>
        </b-row>
        <validation-observer>
          <b-col md="12">
            <div class="d-flex align-items-end justify-content-center for-mobile">
              <div class="width-input">
                <b-form-group label="Enviar para e-mail cadastrado:" label-for="E-mail Cadastrado">
                  <validation-provider
                    #default="{ errors }"
                    name="E-mail Cadastrado"
                    rules="email"
                  >
                    <b-form-input
                      id="Email Cadastrado"
                      v-model="emailLogado"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="container-botao-margin-personalizada">
                <b-button
                  type="submit"
                  variant="custom"
                  class="mr-1"
                  @click.prevent="enviarEmail(emailLogado)"
                  :disabled="disableButtons"
                >
                  <span>Enviar</span>
                </b-button>
              </div>
            </div>
            <validation-provider
              name="Email"
              #default="{ errors }"
              :rules=" false ? 'required|email' : 'email'"
            >
              <div class="d-flex align-items-end justify-content-center for-mobile">
                <div class="width-input">
                  <b-form-group label="Enviar para e-mail alternativo:" label-for="email-alternativo">
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="emailAlternativo"
                      id="email"
                      name="email-alternativo"
                      Type="email"
                      autocomplete="off"
                      :disabled="disableButtons"
                    />
                    <small v-if="errors.length > 0" class="text-danger">
                      {{ errors[0]}}
                    </small>
                  </b-form-group>
                </div>
                <div class="container-botao-margin-personalizada">
                  <b-button
                    type="submit"
                    variant="custom"
                    :class="(errors.length > 0)?'mr-1 btn-with-error':'mr-1'"
                    @click.prevent="enviarEmail(emailAlternativo)"
                    :disabled="errors.length > 0 || emailAlternativo===''"
                  >
                    <span>Enviar</span>
                  </b-button>
                </div>
              </div>
            </validation-provider>
          </b-col>
        </validation-observer>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { modalGenericModel } from "@/libs/sweetalerts";
import { VTree, VSelectTree } from "vue-tree-halower";
import "@core/scss/vue/libs/halower-tree.min.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {getUserData} from "@/auth/utils";
import {
  BForm,
  BImg,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BForm,
    BImg,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BBadge,
    BCol,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    VSelectTree,
    VTree,
    VuePerfectScrollbar,
  },

  props: {
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      emailLogado: '',
      emailAlternativo: '',
      loading: {
        pagina: true,
      },
      email: '',
      disableButtons: false,
    };
  },

  created() {
    const usuario = getUserData();
    this.emailLogado = usuario.usuario.email;
    this.loading.pagina = false;
  },

  mounted() {
    this.verificaStatusAplicacao();
  },

  methods: {
    enviarEmail(email){
      this.loading.pagina = true;
      let rota = this.$api.enviarEmailAplicacao;
      this.email = email;
      let form = this.formataDados();
      this.$http
        .post(rota,form)
        .then((res) => {
          if (res.status == 200) {
            modalGenericModel(
              'E-mail enviado com sucesso!',
              'Acesse seu e-mail e confira as informações da aplicação',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            )
            this.emailAlternativo='';
            this.loading.pagina = false;
          }
        }).catch(() => {
          modalGenericModel(
            'E-mail inválido',
            'O e-mail que você digitou parece não estar correto. Favor inserir um endereço de e-mail válido',
            require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
            'Entendi'
          )
          this.loading.pagina = false;
        });
    },

    formataDados(){
        return {
            email:        this.email,
            id_aplicacao: this.applicationData.id_aplicacao,
        };
    },

    verificaStatusAplicacao(){
      if(
        this.applicationData.situacao_aplicacao == 'Em pausa'   ||
        this.applicationData.situacao_aplicacao == 'Finalizado' ||
        this.applicationData.situacao_aplicacao == 'Cancelado'
      ){
        this.disableButtons = true;
      }else{
        this.disableButtons = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.container-custom-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 15px;

  height: auto;
  width: auto;
  max-height: 13vh;
  max-width: 32vw;

  background-color: #EFEFEC;
  border-radius: 15px;

  @media (max-width: 509px){
    flex-direction: column;
    justify-content: center;
    padding-top: 2vh;
    padding-bottom: 5vh;
    min-height: 32vh;
    max-width: 45vw;
  }

  @media (min-width: 510px) and (max-width: 970px){
    max-width: 50vw;
  }
}

.c-button-personalizado{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  max-height: 12vh;

  @media (max-width: 509px){
    text-align: center;
    height: auto;
    width: 90%;
  }

  @media (min-width: 510px) and (max-width: 970px){
    align-items: center;
    height: 90%;
    width: 25%;
  }
}

.b-personalizado{
  cursor: default !important;
  width: 100%;
  height: auto;
  margin: 30%;
  background-color: #1572CD;
  border: none;
  opacity: 1;
}

.img-link{
  width:100%;
  min-width:30px;

  @media (max-width: 509px){
    min-width: 30px;
    min-height: auto;
  }

  @media (min-width: 510px) and (max-width: 970px){
    min-width: 30px;
    min-height: auto;
  }
}

.c-message-personalizado{
  align-items: center !important;
  display: flex !important;
  text-align: left !important;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 3%;
  max-height: 12vh;
  max-width: 100%;
  font-size: 14px;

  @media (max-width: 509px){
    text-align: center !important;
    font-size: 13px;
  }

  @media (min-width: 510px) and (max-width: 1320px){
    font-size: 12px;
  }
}

.margin-personalizada{
  margin-bottom: 40px;
  margin-top: 30px;
}

.margin-personalizada-botoes{
  margin-top: 70px;
}


.container-botao-margin-personalizada{
  margin-bottom: 14px;
  margin-left: 15px;
  @media (max-width: 509px){
    margin-bottom: 0;
    margin-left: 0;
    margin-right: -15px;
  }
}

.width-input{
  width: 25vw;
   @media (max-width: 509px){
    width: 70vw;
   }
}

.for-mobile{
  @media (max-width: 509px){
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}

.btn-with-error{
  margin-bottom: 20px;
}

</style>






