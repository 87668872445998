<template>
  <div>
    <dados-aplicacao-visualizar
      v-if="subAccess === subAccessEnum.visualizar"
      :applicationData="applicationData"
      @changeComponent="changeComponent"
    />
    <dados-aplicacao-editar
      v-if="subAccess === subAccessEnum.editar"
      :applicationData="applicationData"
      @changeComponent="changeComponent"
      @changeComponentWithReloadApplication="changeComponentWithReloadApplication"
    />
  </div>
</template>

<script>
import DadosAplicacaoVisualizar from './dados-aplicacao-components/DadosAplicacaoVisualizar.vue'
import DadosAplicacaoEditar from './dados-aplicacao-components/DadosAplicacaoEditar.vue'
import { $subAccess } from "./dados-aplicacao-components/accessEnum.js";

export default {
  components: {
    DadosAplicacaoVisualizar,
    DadosAplicacaoEditar
  },

  props: {
    subAccess: {
      type: String,
      required: true
    },
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      subAccessEnum: $subAccess
    }
  },

  methods: {
    changeComponent(component){
      this.$emit('changeSubAccess', component)
    },
    changeComponentWithReloadApplication(component){
      this.$emit('changeSubAccess', component)
      this.$emit('reloadInformation', false)
    }
  },
}
</script>
