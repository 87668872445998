<template>
  <b-row
    cols="1"
    class="page"
  >
    <b-col>
      <b-row
        cols="1"
        cols-sm="2"
        class="mb-2"
      >
        <b-col>
          <div class="pageTitle">
            {{ applicationData.nome_aplicacao }}
          </div>
          <div class="pageSubText">
            {{ applicationData.descricao_aplicacao }}
          </div>
        </b-col>
        <b-col
          class="d-lg-inline-flex justify-content-lg-end mb-n1 text-center"
        >
          <div
            v-if="$can(actionRule.atualizar, entityRule.moduloCora.aplicacaoStatus)"
            class="custom-my-1"
          >
            <button-generic-with-feather-icon
              buttonDescription="Pausar ou cancelar aplicação"
              :iconName="'PauseCircleIcon'"
              @buttonClickAction="callPauseOrCancelApplication"
            />
          </div>
          <div
            v-if="$can(actionRule.visualizar, entityRule.moduloCora.aplicacao)"
            class="ml-lg-1 mt-1-lg-down"
          >
            <button-generic-with-feather-icon
              :buttonDescription="'Visualizar aplicação'"
              :iconName="'EyeIcon'"
              @buttonClickAction="callVisualizationApplication"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-overlay :show="loadingPage" rounded="lg" opacity="0.7">
      <validation-observer ref="simpleRules">
        <div class="px-1">
          <b-row>
            <b-col md="6" class="mb-1">
              <validation-provider #default="{ errors }" rules="required|regex:^([a-zA-Z0-9 çÇãÃõÕáÁéÉíÍóÓúÚàÀâÂêÊôÔ]+)$" name="Nome da Aplicação">
                <b-form-group
                  label="Nome da Aplicação*"
                  label-for="Input-Nome"
                >
                  <b-form-input
                    v-model="form.nomeAplicacao.value"
                    :state="errors.length > 0 || formErrosApi.nome ? false : null"
                    id="Input-Nome"
                    placeholder="(máx. 50 caracteres)"
                    maxlength="50"
                    autocomplete="off"
                    :disabled="form.nomeAplicacao.disabled"
                  />
                  <small
                  v-if="errors.length > 0 &&
                        errors[0] === 'O campo Nome da Aplicação é obrigatório'
                      "
                  class="text-danger"
                >
                  Campo Obrigatório
                </small>

                <small
                  v-if="errors.length > 0 &&
                        errors[0] === 'O campo Nome da Aplicação possui um formato inválido'"
                  class="text-danger"
                >
                  Não é permitido caracteres especiais
                </small>
                  <small v-if="formErrosApi.nome" class="text-danger">
                      {{ formErrosApi.nome }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" class="mb-1">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="Grupo*"
                  label-for="Select-Grupo"
                >
                  <v-select
                    id="Select-Grupo"
                    v-model="form.grupo.selecionado"
                    @input="setEmpresasPorIdGrupo"
                    item-text="descricao"
                    label="descricao"
                    maxlength="20"
                    :reduce="option => option.id_empresa_grupo"
                    :options="form.grupo.dadosSelect"
                    placeholder="Selecione o Grupo"
                    :disabled="form.grupo.disabled"
                    :clearable="false"
                  >
                    <span slot="no-options">Nenhum Grupo encontrado.</span>
                  </v-select>
                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="mb-1">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  :state="errors.length > 0 || formErrosApi.idEmpresa ? false : null"
                  label="Empresa*"
                  label-for="Input-Empresa"
                >
                  <v-select
                    id="Select-Empresa"
                    v-model="form.empresa.selecionado"
                    variant="custom"
                    item-text="nome_fantasia"
                    item-value="id_empresa"
                    label="nome_fantasia"
                    maxlength="20"
                    :reduce="option => option.id_empresa"
                    :options="form.empresa.dadosSelect"
                    placeholder="Selecione a Empresa"
                    :disabled="form.empresa.disabled"
                    :clearable="false"
                  >
                    <span slot="no-options">Nenhuma Empresa encontrada.</span>
                  </v-select>
                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                  <small v-if="formErrosApi.idEmpresa" class="text-danger">
                      {{ formErrosApi.idEmpresa }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" class="mb-1">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  :state="errors.length > 0 || formErrosApi.idQuestionario ? false : null"
                  label="Questionário*"
                  label-for="Select-Questionario"
                >
                  <v-select
                    v-model="form.questionario.selecionado"
                    id="Select-Questionario"
                    variant="custom"
                    item-text="descricao"
                    item-value="descricao"
                    :options="form.questionario.dadosSelect"
                    placeholder="Selecione o questionário"
                    label="descricao"
                    :disabled="form.questionario.disabled"
                    :clearable="false"
                  >
                    <span slot="no-options">
                      Nenhum Questionário encontrado.
                    </span>
                  </v-select>
                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                  <small v-if="formErrosApi.idQuestionario" class="text-danger">
                      {{ formErrosApi.idQuestionario }}
                  </small>
                </b-form-group>
                <div
                  class="d-flex align-items-center IconeEInformacoes"
                  v-if="form.questionario.selecionado && form.questionario.selecionado.tipo === 'Padronizado'"
                  @click="callModalInfoByQuiz()"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="distanciaIconeLabel"
                  />
                  Informações sobre o questionário
                </div>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="3"
              sm="6"
              cols="12"
            >
              <validation-provider
                name="Data Início"
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="Data de Início*"
                  label-for="dataInicio"
                >
                  <b-input-group
                    class="tamanhoInputDate"
                  >
                    <b-form-input
                      id="dataInicio"
                      type="text"
                      :state="errors.length > 0 || formErrosApi.dataInicio || formErrosDataHora.dataInicio ? false : null"
                      v-model="form.dataInicio.input"
                      v-mask="'##/##/####'"
                      placeholder="00/00/0000"
                      autocomplete="off"
                      :disabled="form.dataInicio.disabled"
                      @input="verificaDataHoraValida(form.dataInicio.input, 'DD/MM/YYYY', 'dataInicio')"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        class="form-control d-flex align-items-center"
                        :style="errors.length > 0 || formErrosApi.dataInicio || formErrosDataHora.dataInicio ? 'border: 1px solid red' : null"
                        button-only
                        right
                        v-model="form.dataInicio.picker"
                        v-bind="labels[locale]"
                        :locale="locale"
                        :disabled="form.dataInicio.disabled"
                        button-variant="custom-blue"
                        :hide-header="true"
                        clearable
                        @input="setDataPicker('dataInicio')"
                        :label-help="'Use as setas do teclado para navegar'"
                        :min="dataHoje"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ errors[0] }}
                  </small>
                  <small v-if="formErrosApi.dataInicio" class="text-danger">
                    {{ formErrosApi.dataInicio }}
                  </small>
                  <small v-if="formErrosDataHora.dataInicio" class="text-danger">
                    {{ formErrosDataHora.dataInicio }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="3"
              sm="6"
              cols="12"
            >
              <validation-provider
                name="Data Término"
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="Data de Término*"
                  label-for="dataTermino"
                >
                  <b-input-group
                    class="tamanhoInputDate"
                  >
                    <b-form-input
                      id="dataTermino"
                      :state="errors.length > 0 || formErrosApi.dataTermino || formErrosDataHora.dataTermino ? false : null"
                      v-model="form.dataTermino.input"
                      type="text"
                      v-mask="'##/##/####'"
                      placeholder="00/00/0000"
                      autocomplete="off"
                      :disabled="form.dataTermino.disabled"
                      @input="verificaDataHoraValida(form.dataTermino.input, 'DD/MM/YYYY', 'dataTermino')"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        class="form-control d-flex align-items-center"
                        :style="errors.length > 0 || formErrosApi.dataTermino || formErrosDataHora.dataTermino ? 'border: 1px solid red' : null"
                        button-only
                        right
                        v-model="form.dataTermino.picker"
                        v-bind="labels[locale]"
                        :locale="locale"
                        :disabled="form.dataTermino.disabled"
                        button-variant="custom-blue"
                        :hide-header="true"
                        clearable
                        @input="setDataPicker('dataTermino')"
                        :label-help="'Use as setas do teclado para navegar'"
                        :min="applicationData.situacao_aplicacao === applicationStatus.agendado ? form.dataTermino.dataTerminoMinima : dataHoje"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ errors[0] }}
                  </small>
                  <small v-if="formErrosApi.dataTermino" class="text-danger">
                    {{ formErrosApi.dataTermino }}
                  </small>
                  <small v-if="formErrosDataHora.dataTermino" class="text-danger">
                    {{ formErrosDataHora.dataTermino }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="3"
              sm="6"
              cols="12"
            >
              <validation-provider
                name="Hora Inicio"
                #default="{ errors }"
                rules="required"
              >
              <b-form-group
                  label="Hora de Início*"
                  label-for="horaInicio"
                >
                  <b-input-group
                    class="tamanhoInputTime"
                  >
                    <b-form-input
                      id="horaInicio"
                      name="horaInicio"
                      v-model="form.horaInicio.input"
                      :state="errors.length > 0 || formErrosApi.horaInicio || formErrosDataHora.horaInicio ? false : null"
                      type="text"
                      placeholder="08:00"
                      v-mask="'##:##'"
                      :disabled="form.horaInicio.disabled"
                      @input="verificaDataHoraValida(form.horaInicio.input, 'HH:mm', 'horaInicio')"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="form.horaInicio.picker"
                        class="form-control"
                        :style="errors.length > 0 || formErrosApi.horaInicio || formErrosDataHora.horaInicio ? 'border: 1px solid red' : null"
                        v-bind="labels[locale]"
                        :locale="locale"
                        :disabled="form.horaInicio.disabled"
                        button-only
                        right
                        button-variant="custom-blue"
                        :hide-header="true"
                        @input="setHoraPicker('horaInicio')"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{  errors[0]}}
                  </small>
                  <small v-if="formErrosApi.horaInicio" class="text-danger">
                    {{ formErrosApi.horaInicio }}
                  </small>
                  <small v-if="formErrosDataHora.horaInicio" class="text-danger">
                    {{ formErrosDataHora.horaInicio }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="3"
              sm="6"
              cols="12"
            >
              <validation-provider
                name="Hora Término"
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label="Hora de Término*"
                  label-for="horaTermino"

                >
                  <b-input-group
                    class="tamanhoInputTime"
                  >
                    <b-form-input
                      id="horaTermino"
                      name="horaTermino"
                      class="border-right-0"
                      v-model="form.horaTermino.input"
                      :state="errors.length > 0 || formErrosApi.horaTermino || formErrosDataHora.horaTermino ? false : null"
                      type="text"
                      placeholder="08:00"
                      v-mask="'##:##'"
                      :disabled="form.horaTermino.disabled"
                      @input="verificaDataHoraValida(form.horaTermino.input, 'HH:mm', 'horaTermino')"
                    ></b-form-input>
                    <b-input-group-append
                     class="border-left-0"
                    >
                      <b-form-timepicker
                        v-model="form.horaTermino.picker"
                        class="form-control"
                        :style="errors.length > 0 || formErrosApi.horaTermino || formErrosDataHora.horaTermino ? 'border: 1px solid red' : null"
                        v-bind="labels[locale]"
                        :locale="locale"
                        :disabled="form.horaTermino.disabled"
                        button-only
                        right
                        button-variant="custom-blue"
                        :hide-header="true"
                        @input="setHoraPicker('horaTermino')"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ errors[0] }}
                  </small>
                  <small v-if="formErrosApi.horaTermino" class="text-danger">
                    {{ formErrosApi.horaTermino }}
                  </small>
                  <small v-if="formErrosDataHora.horaTermino" class="text-danger">
                    {{ formErrosDataHora.horaTermino }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="6" md="12">
              <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label="Descrição*"
                label-for="descricao-input"
              >
                <b-form-input
                    v-model="form.descricaoAplicacao.value"
                    :state="errors.length > 0 || formErrosApi.descricao ? false : null"
                    id="descricao-input"
                    placeholder="(máx. 115 caracteres)"
                    maxlength="115"
                    autocomplete="off"
                    :disabled="form.descricaoAplicacao.disabled"
                  />
                <small v-if="errors.length > 0" class="text-danger">
                  Campo Obrigatório
                </small>
                <small v-if="formErrosApi.descricao" class="text-danger">
                    {{ formErrosApi.descricao }}
                </small>
              </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="3" md="6" class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="form.aplicacao.opcaoSelecionada"
                  name="some-radios"
                  value="A"
                  :disabled="form.aplicacao.disabled"
                >
                  Utilizar Questões Personalizadas
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="3" md="6" class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="form.aplicacao.opcaoSelecionada"
                  name="some-radios"
                  value="B"
                  :disabled="form.aplicacao.disabled"
                >
                  Não Utilizar Questões Personalizadas
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="form.aplicacao.opcaoSelecionada === 'A'"
            class="custom-margin-top mb-1"
          >
            <div
              class="w-100"
              v-for="(question, index) in form.customQuestions"
              :key="index"
            >
              <b-col class="mb-1">
                <div>
                  <small> Questão Personalizada {{ index+1 }} </small>
                </div>
                <div>
                  <small class="IconeEInformacoes"> Máximo de 2 questões personalizadas </small>
                </div>
              </b-col>

              <b-col
                class="gapQuestoesPersonalizadas"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <div v-if="index<1">
                    <b-form-input
                      v-model="question.enunciado"
                      :state="errors.length > 0 ? false : null"
                      :id="'input-enunciado-questao'+index"
                      placeholder="Digite aqui o enunciado*"
                      autocomplete="off"
                      :disabled="applicationData.situacao_aplicacao !== applicationStatus.agendado ? true : false"
                      maxlength="115"
                    />
                  </div>
                  <div v-else>
                    <b-input-group
                      :id="'input-enunciado-questao'+index"
                    >
                      <b-form-input
                        v-model="question.enunciado"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Digite aqui o enunciado*"
                        autocomplete="off"
                        :disabled="applicationData.situacao_aplicacao !== applicationStatus.agendado ? true : false"
                        maxlength="115"
                      />
                      <template #append>
                        <b-input-group-text
                          @click.prevent="applicationData.situacao_aplicacao == applicationStatus.agendado ? removeQuestaoPersonalizada(index) : null"
                          class="btn-cursor-pointer border-left-0"
                          :style="errors.length > 0 ? 'border-color: red' : null"
                          :class="applicationData.situacao_aplicacao !== applicationStatus.agendado ? 'background-disabled' : null"
                        >
                          <b-img
                            :src="require('@/assets/custom-icons/trash.svg')"
                            class="icon-trash"
                            style="cursor: pointer;"
                          />
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                  </div>


                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                </validation-provider>
              </b-col>

              <b-col
                class="gapQuestoesPersonalizadas"
                v-for="(option, optionIndex) in question.opcoes"
                :key="optionIndex"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-input-group
                    :id="'input-enunciado-questao'+index+'opcao'+optionIndex"
                  >
                    <b-form-input
                      v-model="option.alternativa"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="'Digite aqui a alternativa '+numeroParaLetra(optionIndex)+')'"
                      autocomplete="off"
                      class="border-right-0"
                      :disabled="applicationData.situacao_aplicacao !== applicationStatus.agendado ? true : false"
                      maxlength="115"
                    />
                    <template #append>
                      <b-input-group-text
                        @click.prevent="(applicationData.situacao_aplicacao !== applicationStatus.agendado ? false : true) && question.opcoes.length > 2 ? removeCustomQuestionsOptions(index, optionIndex) : null"
                        class="btn-cursor-pointer border-left-0"
                        :style="errors.length > 0 ? 'border-color: red' : null"
                        :class="applicationData.situacao_aplicacao !== applicationStatus.agendado ? 'background-disabled' : null"
                      >
                        <b-img
                          :src="require('@/assets/custom-icons/trash.svg')"
                          class="icon-trash"
                          style="cursor: pointer;"
                        />
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                </validation-provider>
              </b-col>
              <b-col md="6" class="mb-1">
                <small class="IconeEInformacoes"> Mínimo de 2 e máximo de 10 alternativas </small>
              </b-col>
              <b-col class="my-1">
                <b-button
                  type="submit"
                  variant="custom"
                  class="min-button"
                  @click.prevent="insertNewOption(index)"
                  :disabled="(applicationData.situacao_aplicacao !== applicationStatus.agendado ? true : false) || question.opcoes.length == 10"
                >
                  +
                </b-button>
                <small class="ml-custom">
                  Inserir mais alternativas
                </small>
              </b-col>
            </div>
            <b-col v-if="form.customQuestions.length<10">
              <b-button
                type="submit"
                variant="custom"
                class="min-button"
                @click.prevent="insertNewQuestion()"
                :disabled="(applicationData.situacao_aplicacao !== applicationStatus.agendado ? true : false) || form.customQuestions.length > 9"
              >
                +
              </b-button>
              <small
                class="ml-custom"
                :class="(applicationData.situacao_aplicacao !== applicationStatus.agendado ? true : false) || form.customQuestions.length > 9 ? 'text-muted' : 'none'"
              >
                Questão Personalizada {{ form.customQuestions.length +1 }}
              </small>
            </b-col>
          </b-row>
          <b-row
            class="botoes mb-1"
          >
            <b-col
              md="12"
              class="text-center"
            >
              <b-button
                type="submit"
                variant="outline-secondary"
                class="mr-2 mt-1"
                @click.once="callVisualizationApplication()"
              >
                <span>
                  Cancelar
                </span>
              </b-button>
              <b-button
                type="submit"
                variant="custom"
                class="mt-1"
                @click.prevent="submitForm()"
              >
                <span>
                  Salvar
                </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </b-overlay>


  </b-row>
</template>

<script>
import ButtonGenericWithFeatherIcon from '@/views/components/button/ButtonGenericWithFeatherIcon.vue'
import { $regraEntidade, $regraAcao } from "@/custom-enum/regras-enum.js";
import { $typeStatus, $subAccess } from "./accessEnum.js";
import {
    BImg,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BCardText,
    BFormRadio,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend
  } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required,regex } from "@validations";
import vSelect from "vue-select";
import { modalGenericModel } from '@/libs/sweetalerts'
import moment from 'moment'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    regex,
    vSelect,
    BImg,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BFormRadio,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BCardText,
    ButtonGenericWithFeatherIcon
  },

  props: {
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      entityRule: $regraEntidade,
      actionRule: $regraAcao,
      applicationStatus: $typeStatus,
      loadingPage: true,
      countTimer: null,
      dataHoje: moment().format('YYYY-MM-DD'),
      form: {
        nomeAplicacao: {
          value: null,
          disabled: false
        },
        descricaoAplicacao: {
          value: null,
          disabled: false
        },
        aplicacao: {
          opcaoSelecionada: 'B',
          disabled: false
        },
        grupo: {
          selecionado: null,
          disabled: false,
          dadosSelect: []
        },
        empresa: {
          selecionado: null,
          disabled: true,
          dadosSelect: []
        },
        questionario: {
          selecionado: null,
          disabled: false,
          dadosSelect:  []
        },
        dataInicio: {
          input: null,
          picker: null,
          disabled: false
        },
        dataTermino: {
          input: null,
          picker: null,
          disabled: false,
          dataTerminoMinima: null
        },
        horaInicio: {
          input: null,
          picker: null,
          disabled: false
        },
        horaTermino: {
          input: null,
          picker: null,
          disabled: false
        },
        customQuestions: [
          {
            enunciado: null,
            opcoes: [
              {
                alternativa: null
              },
              {
                alternativa: null
              }
            ]
          }
        ]
      },
      formErrosApi: {
        nome: null,
        idEmpresa: null,
        idQuestionario: null,
        dataInicio: null,
        dataTermino: null,
        horaInicio:  null,
        horaTermino: null,
        descricao: null
      },
      formErrosDataHora: {
        dataInicio: null,
        dataTermino: null,
        horaInicio:  null,
        horaTermino: null
      },
      locale: 'pt-BR',
      labels:{
        'pt-BR': {
          labelHours: 'Horas',
          labelMinutes: 'Minutos',
          labelSeconds: 'Segundos',
          labelAmpm: 'AM / PM',
          labelAm: 'AM',
          labelPm: 'PM',
          labelIncrement: 'Aumentar',
          labelDecrement: 'Reduzir',
          labelSelected: 'Hora Selecionada',
          labelNoTimeSelected: 'Nenhum horário selecionado',
          labelCloseButton: 'fechar',
        },
      }
    }
  },

  async mounted(){
    await this.populaGrupos();
    await this.populaQuestionarios();
    await this.populaFormulario();
    this.loadingPage = false;
  },

  methods: {
    async populaGrupos() {
      await this.$http.get(this.$api.GruposBusca).then((res) => {
        this.form.grupo.dadosSelect = res.data.data;
      });
    },

    async populaQuestionarios(){
      await this.$http.get(this.$api.BuscaQuestionariosPorModulo(5))
      .then((res) => {
        this.form.questionario.dadosSelect = res.data;
      });
    },

    async populaFormulario(){
      this.form.nomeAplicacao.value = this.$props.applicationData.nome_aplicacao;
      this.form.grupo.selecionado = this.$props.applicationData.id_empresa_grupo;
      await this.setEmpresasPorIdGrupo(this.$props.applicationData.id_empresa_grupo);
      this.form.empresa.selecionado = this.$props.applicationData.id_empresa;
      this.form.questionario.selecionado = this.form.questionario.dadosSelect.find(objeto => objeto.id_questionario === this.$props.applicationData.id_questionario);
      this.form.dataInicio.input = this.$props.applicationData.data_inicio_aplicacao;
      this.form.dataTermino.input = this.$props.applicationData.data_termino_aplicacao;
      this.preparaDataTerminoMinima();
      this.form.horaInicio.input = this.$props.applicationData.horario_inicio_aplicacao;
      this.form.horaTermino.input = this.$props.applicationData.horario_termino_aplicacao;
      this.form.descricaoAplicacao.value = this.$props.applicationData.descricao_aplicacao;
      this.preparaQuestoesPersonalizadas(this.$props.applicationData.aplicacao_questao)
      this.disableFormBySituation();
    },

    setHoraPicker(tipoHora){
      if(tipoHora === 'horaInicio'){
        this.form.horaInicio.input = this.form.horaInicio.picker
      }else if(tipoHora === 'horaTermino'){
        this.form.horaTermino.input = this.form.horaTermino.picker
      }
    },

    setDataPicker(tipoData){
      if(tipoData === 'dataInicio'){
        this.form.dataInicio.input = moment(this.form.dataInicio.picker).format('DD/MM/YYYY')
      }else if(tipoData === 'dataTermino'){
        this.form.dataTermino.input = moment(this.form.dataTermino.picker).format('DD/MM/YYYY')
      }
    },

    callVisualizationApplication(){
      this.$emit('changeComponent', $subAccess.visualizar)
    },

    callPauseOrCancelApplication(){
      this.$router.push(
        {
          name: 'visualizar-aplicacao-pausar-cancelar',
          params: { applicationData: this.$props.applicationData }
        }
      );
    },

    disableFormBySituation(){
      this.form.grupo.disabled = true;
      this.form.empresa.disabled = true;
      this.form.questionario.disabled = true;
      if(
        this.$props.applicationData.situacao_aplicacao === this.applicationStatus.emAndamento ||
        this.$props.applicationData.situacao_aplicacao === this.applicationStatus.emPausa
      ){
        this.form.nomeAplicacao.disabled = true;
        this.form.dataInicio.disabled = true;
        this.form.horaInicio.disabled = true;
        this.form.descricaoAplicacao.disabled = true;
        this.form.aplicacao.disabled = true;
      }
    },

    async setEmpresasPorIdGrupo(idEmpresaGrupo) {
      await this.$http.get(this.$api.EmpresasBusca, { params: { id_empresa_grupo: idEmpresaGrupo } })
      .then((res) => {
        this.form.empresa.dadosSelect = res.data;
      });
    },

    async alterarAplicacao(){
      this.loadingPage = true;
      let form = this.formataDadosFormulario();
      this.limpaErrosApi();
      await this.$http.put(this.$api.cora.aplicacaoShowAndEdit(this.$props.applicationData.id_aplicacao), form)
        .then(() => {
          modalGenericModel(
            'Sucesso!',
            'As edições feitas nesta aplicação foram salvas com sucesso!',
            require('@/assets/custom-icons/cora-icons/success.png'),
            'Ok'
          ).then(() => {
            this.$emit('changeComponentWithReloadApplication', $subAccess.visualizar)
          })
        })
        .catch((error) => {
          this.tratarErrosAPI(error);
        });
      this.loadingPage = false;
    },

    limpaErrosApi(){
      this.formErrosApi = {
        nome: null,
        idEmpresa: null,
        idQuestionario: null,
        dataInicio: null,
        dataTermino: null,
        horaInicio:  null,
        horaTermino: null,
        descricao: null
      }
    },

    formataDadosFormulario(){
      const dados = {
        nome:                       this.form.nomeAplicacao.value,
        id_empresa:                 this.form.empresa.selecionado,
        id_questionario:            this.form.questionario.selecionado.id_questionario,
        data_inicio:                moment(this.form.dataInicio.input, "DD/MM/YYYY").format('YYYY-MM-DD'),
        data_termino:               moment(this.form.dataTermino.input, "DD/MM/YYYY").format('YYYY-MM-DD'),
        hora_inicio:                moment(this.form.horaInicio.input, "HH:mm").format('HH:mm:ss'),
        hora_termino:               moment(this.form.horaTermino.input, "HH:mm").format('HH:mm:ss'),
        descricao:                  this.form.descricaoAplicacao.value,

      };

      if(this.form.aplicacao.opcaoSelecionada === 'A'){
        dados.questoes_personalizadas = this.form.customQuestions
      }

      return dados;
    },

    submitForm(){
      this.$refs.simpleRules.validate().then((success) => {
        if (success && !Object.values(this.formErrosDataHora).some(value => value !== null)) {
          this.alterarAplicacao();
        }
      });
    },

    verificaDataHoraValida(dataOuHora, tipo, stringErroApi){
      this.formErrosDataHora[stringErroApi] = null;
      clearTimeout(this.countTimerData);
      this.countTimerData = setTimeout(() => {
        if(moment(dataOuHora, tipo, true).isValid() === false){
          this.formErrosDataHora[stringErroApi] = 'Campo preenchido incorretamente';
        }
      }, 200);
    },

    tratarErrosAPI(error){
      let erros = error.response.data.error ?? error.response.data.errors;
      this.formErrosApi.nome              =   erros.nome            ? erros.nome[0]            : null;
      this.formErrosApi.idEmpresa         =   erros.id_empresa      ? erros.id_empresa[0]      : null;
      this.formErrosApi.idQuestionario    =   erros.id_questionario ? erros.id_questionario[0] : null;
      this.formErrosApi.dataInicio        =   erros.data_inicio     ? erros.data_inicio[0]     : null;
      this.formErrosApi.dataTermino       =   erros.data_termino    ? erros.data_termino[0]    : null;
      this.formErrosApi.horaInicio        =   erros.hora_inicio     ? erros.hora_inicio[0]     : null;
      this.formErrosApi.horaTermino       =   erros.hora_termino    ? erros.hora_termino[0]    : null;
      this.formErrosApi.descricao         =   erros.descricao       ? erros.descricao[0]       : null;
    },

    callModalInfoByQuiz(){
      modalGenericModel(
        this.form.questionario.selecionado.descricao_tecnica,
        this.form.questionario.selecionado.observacao,
        '',
        'Entendi &#x2714;'
      )
    },

    preparaDataTerminoMinima(){
      if(this.form.dataInicio.input){
        this.form.dataTermino.dataTerminoMinima = moment(this.form.dataInicio.input, "DD/MM/YYYY").format('YYYY-MM-DD');
        return;
      }
      this.form.dataTermino.dataTerminoMinima = this.dataHoje;
    },

    removeCustomQuestionsOptions(questionKey, optionKey){
      this.form.customQuestions[questionKey].opcoes.splice(optionKey, 1)
    },

    insertNewOption(arrayIndex) {
      this.form.customQuestions[arrayIndex].opcoes.push(
        {
          alternativa: null
        }
      )
    },

    insertNewQuestion() {
      this.form.customQuestions.push(
        {
          enunciado: null,
          opcoes: [
            {
              alternativa: null
            },
            {
              alternativa: null
            }
          ]
        }
      )
    },

    numeroParaLetra(numero) {
      const letras = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
      if (numero >= 0 && numero < letras.length) {
          return letras[numero];
      }
    },

    preparaQuestoesPersonalizadas(aplicacaoQuestao) {
      if(aplicacaoQuestao && aplicacaoQuestao.length > 0){
        this.form.customQuestions = []
        for (let i = 0; i < aplicacaoQuestao.length; i++) {
          this.form.customQuestions.push(
          {
            enunciado: null,
            opcoes: []
          })
          this.form.customQuestions[i].enunciado = aplicacaoQuestao[i].questao[0].conteudo
          for (let f = 0; f < aplicacaoQuestao[i].questao[0].opcoes.length; f++) {
            this.insertNewOption(i)
            this.form.customQuestions[i].opcoes[f].alternativa = aplicacaoQuestao[i].questao[0].opcoes[f].conteudo
          }
        }
        this.form.aplicacao.opcaoSelecionada = 'A'
      }
    },

    removeQuestaoPersonalizada(index){
        this.form.customQuestions[index].enunciado = null;
        this.form.customQuestions[index].opcoes = null;
        this.form.customQuestions.splice(index,1);
      }
  },

  watch: {
    'form.dataInicio.input': function (novoValor) {
      if(moment(novoValor, 'DD/MM/YYYY', true).isValid() === true){
        this.preparaDataTerminoMinima()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .page{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }
  .pageTitle{
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #5E5873;
  }
  .pageSubText{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5E5873;
  }

  .IconeEInformacoes{
    margin-top: -5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    color: #B9B9C3;
    cursor: pointer;
  }
  .distanciaIconeLabel{
    margin-right: 5px;
  }

  .tamanhoInputDate{
    min-width: 190px !important;
  }

  .tamanhoInputTime{
    min-width: 150px !important;
  }

  .custom-margin-top{
    margin-top: 0.925rem;
  }

  .gapQuestoesPersonalizadas{
    margin-bottom: 4px;
  }
  .min-button{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    margin-top: 0!important;
    text-align: center;
  }
  .ml-custom{
    margin-left: 0.225rem;
  }
  .icon-trash{
    width: 1rem;
    height: auto;
  }

  .background-disabled{
    background-color: #efefef;
  }

  @media (max-width: 991.98px) {
    .mt-1-lg-down {
        margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    .custom-my-1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 575px) {
    .page{
      text-align: center;
    }
  }
</style>
