
<template>
<div>
  <div class="p-2 card">
    <b-overlay :show="loading" rounded="lg" opacity="0.7">
      <span class="info-message">
      Ao clicar no ícone abaixo, um novo arquivo de respostas do questionário aplicado será gerado.
      Os dados das respostas serão disponibilizados em planilha excel (.xls).
    </span>
      <b-row class="margin-personalizada">
        <b-col md="12" align="center">
          <div>
            <div class="container-custom-message  cursor-pointer" @click="fireModalGenerateCsvReport()">
              <div class="c-button-personalizado">
                <b-button
                    type="submit"
                    variant="custom"
                    class="b-personalizado"
                  >
                    <b-img  :src="require('@/assets/custom-icons/download.svg')" class="img-link" />
                  </b-button>
              </div>
              <div class="c-message-personalizado">
                <span class="ml-25 align-middle">
                  <span class="link">Clique aqui</span>
                  para <strong>gerar</strong> o relatório de respostas.
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="align-items-center">
          <b-row  v-if="mensagemResposta === '' ||
                        mensagemResposta === 'Arquivo em preparação' ||
                        mensagemResposta === 'Em Processamento'"
          >
            <div v-if="mensagemResposta === ''" class="info-message">
              <span>
                {{mensagemResposta}}
              </span>
            </div>
            <div v-else>
              <b-spinner
                variant="primary"
                class="icon-loading"
              />
              <span class="info-message custom-margim-bottom">
                Estamos preparando o arquivo para download, por favor, aguarde. Você será notificado assim que o download estiver disponível.
              </span>
            </div>
          </b-row>
          <b-row v-else>
            <b-col>
              <br>
              <b-button
                variant="outline-primary"
                class="btn-icon"
                @click.prevent="baixar"
                :disabled="loadingArquivo || loadingProcessamento"
              >
                <span>
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                    class="mr-1"
                  />
                </span>
                <span>Download do arquivo</span>
              </b-button>
            </b-col>
          </b-row>
      </div>
    </b-overlay>
  </div>
</div>
</template>

<script>
import { VTree, VSelectTree } from "vue-tree-halower";
import "@core/scss/vue/libs/halower-tree.min.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { modalGenericModel } from "@/libs/sweetalerts";

export default {
  components: {
    BImg,
    BRow,
    BBadge,
    BCol,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    VSelectTree,
    VTree,
    VuePerfectScrollbar,
    BSpinner,
  },

  props: {
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      mensagemResposta: null,
      dataCriacaoRelatorio: '',
      loadingArquivo: false,
      loadingProcessamento: false,
      situacaoRelatorio: 'Concluído',
    };
  },

  mounted() {
    this.loadFistAcess();
    this.$eventHub.on('novaMensagemResposta',(_data)=>{
      this.buscaUltimoRelatorio();
    });
  },

  methods: {

    loadFistAcess(){
      this.loading = true;
      this.buscaUltimoRelatorio();
      if(this.mensagemResposta === null){
        this.mensagemResposta = '';
      }
      this.loading = false;
    },

    buscaUltimoRelatorio(){
      let rota = this.$helpers.preparaRotaComParametros(
      this.$api.dataUltimoRelatorio,
        "{idAplicacao}",
        this.applicationData.id_aplicacao
      );
      this.$http
        .get(rota)
        .then((res) => {
          if (res.status === 200 && res.data !== '') {
            this.dataCriacaoRelatorio = res.data.data_relatorio;
            this.dataCriacaoRelatorio = this.dataCriacaoRelatorio.replace('as','às');
            this.mensagemResposta = res.data.situacao_relatorio;
          }
        });
    },

    prepararDownload(){
      this.loading = true;
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.getRespostasAplicacao,
        "{idAplicacao}",
        this.applicationData.id_aplicacao
      );
      this.$http
        .get(rota)
        .then((res) => {
          if (res.status == 200) {
            this.mensagemResposta = res.data;
          }
        })
        .catch(() => {
          modalGenericModel(
            'Não foi realizar o download, tente novamente mais tarde.',
            '',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
        });
      this.loading = false;
    },

    async baixar(){
      this.loading = true;
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.downloadRespostasAplicacao,
        "{idAplicacao}",
        this.applicationData.id_aplicacao
      );
      await this.$http
      .get(rota, {responseType: "blob" })
      .then((res) => {
        if (res.data) {
          this.dataCriacaoRelatorio = res.headers['last-modified'];
          this.dataCriacaoRelatorio = this.dataCriacaoRelatorio.replace('as','às');
          const link = document.createElement('a');
          link.href = URL.createObjectURL(res.data);
          res.headers["content-name-file"] = 'Respostas Aplicação '+this.applicationData.id_aplicacao+' - '+this.applicationData.nome_aplicacao+'.xls';
          link.download = res.headers["content-name-file"];
          link.click();
          URL.revokeObjectURL(link.href);
        }
      }).catch(() => {
        modalGenericModel(
            'Não foi realizar o download, tente novamente mais tarde.',
            '',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
      });


        this.loading = false;
    },

    fireModalGenerateCsvReport() {
      modalGenericModel(
        'Gerar Relatório',
        '<span> Fazer o download da planilha de respostas? <br> Ao confirmar, o processamento ' +
        'do arquivo levará alguns minutos. Você será notificado assim que o download estiver disponível.</span>',
        require('@/assets/custom-icons/cora-icons/help.png'),
        'Confirmar',
        'Cancelar'
      ).then((result) => {
        if (result.value) {
          this.prepararDownload();
        }
      });
    }

  },
};
</script>

<style lang="scss" scoped>

.container-custom-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: auto;
  width: auto;
  max-height: 13vh;
  max-width: 27vw;

  background-color: #EFEFEC;
  border-radius: 15px;

  @media (max-width: 509px){
    flex-direction: column;
    justify-content: center;
    padding-top: 2vh;
    padding-bottom: 5vh;
    min-height: 32vh;
    max-width: 45vw;
  }

  @media (min-width: 510px) and (max-width: 970px){
    max-width: 50vw;
  }
}

.c-button-personalizado{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  max-height: 12vh;

  @media (max-width: 509px){
    text-align: center;
    height: auto;
    width: 90%;
  }

  @media (min-width: 510px) and (max-width: 970px){
    align-items: center;
    height: 90%;
    width: 25%;
  }
}

.b-personalizado{
  cursor: default !important;
  width: 100%;
  height: auto;
  margin: 30%;
  background-color: #1572CD;
  border: none;
  opacity: 1;
}

.img-link{
  width:100%;
  min-width:30px;

  @media (max-width: 509px){
    min-width: 30px;
    min-height: auto;
  }

  @media (min-width: 510px) and (max-width: 970px){
    min-width: 30px;
    min-height: auto;
  }
}

.c-message-personalizado{
  align-items: center !important;
  display: flex !important;
  text-align: left !important;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 3%;
  max-height: 12vh;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 509px){
    text-align: center !important;
    font-size: 13px;
  }

  @media (min-width: 510px) and (max-width: 1320px){
    font-size: 12px;
  }
}

.margin-personalizada{
  margin-bottom: 30px;
  margin-top: 30px;
}

.info-message{
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #82868B;
}

.align-items-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.link, .link:hover{
  color: #2772C0;
  text-decoration: underline;
  font-weight: 600;
}

.icon-loading{
  margin-bottom: 0.425rem;
}

.custom-margim-bottom{
  margin-bottom: 1vh;
}

</style>






