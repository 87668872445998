<template>
  <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
    <section >
      <div v-if="applicationData.descricao_questionario === 'PHQ-9'" class="mt-2">
        <b-row >
          <b-col lg="6" md="6">
            <b-card no-body>
              <div class="altura_linha_1_phq">
                <titulo-generico titulo="Escala de Respostas" subtitulo="Atualizado de acordo com último ciclo"/>
                <escala-respostas-aplicacao v-if="loadingShow"
                    :ApplicationData="dados"
                  />
              </div>
            </b-card>
          </b-col>
          <b-col lg="6" md="6">
              <b-card no-body>
                <div class="altura_linha_1_phq">
                  <titulo-generico titulo="Respostas por gênero"/>
                   <escala-respostas-por-genero-aplicacao v-if="loadingShow"
                    :ApplicationData="dados"
                    :telaCheia="false"
                  />
                </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" md="12">
            <resposta-por-faixa-etaria-aplicacao v-if="loadingShow"
                :ApplicationData="dados"
              />
          </b-col>
        </b-row>

       <b-row>
          <b-col lg="3" md="12">
            <b-card no-body>
                <div class="altura_linha_2_phq_respostas_indicacao">
                  <titulo-generico titulo="Respostas de indicação"/>
                  <respostas-de-indicacao-aplicacao v-if="loadingShow"
                    :ApplicationData="dados"
                  />
                </div>
            </b-card>
          </b-col>
          <b-col lg="9" md="12">
            <b-card no-body>
                <div class="altura_linha_2_phq_niveis_depressao">
                  <titulo-generico titulo="Estatísticas dos Níveis de Depressão"/>
                  <estatisticas-depressao-aplicacao v-if="loadingShow"
                    :ApplicationData="dados"
                  />
                </div>
            </b-card>
          </b-col>
        </b-row >
        <b-row style="margin-right: 0 !important;margin-left: 0 !important;">
          <b-col lg="12" md="12" class="link-tela-cheia" >
              <div class="d-flex justify-content-center align-items-center mt-2">
                <img :src="link" alt="Tela Cheia">
                <div class="d-flex align-items-center ml-2">
                  <span>
                    <router-link
                      :to="{name: 'dashboard-tela-cheia-phq', params: {arquivoAnexo: arquivoAnexo}}"
                      target="_blank"
                      class="custom-link"
                    >Clique aqui</router-link>
                    e você será redirecionado para uma nova página com a dashboard em tela cheia.
                  </span>
                </div>
              </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="applicationData.descricao_questionario === 'DASS-21'" class="mt-2">
        <b-row >
          <b-col lg="6" md="6">
            <b-card no-body>
              <div class="altura_linha_1_dass">
                <titulo-generico titulo="Escala de Respostas" subtitulo="Atualizado de acordo com último ciclo"/>
                <escala-respostas-aplicacao v-if="loadingShow"
                    :ApplicationData="dados"
                  />
              </div>
            </b-card>
          </b-col>
          <b-col lg="6" md="6">
              <b-card no-body>
                <div class="altura_linha_1_dass">
                  <titulo-generico titulo="Respostas por gênero"/>
                   <escala-respostas-por-genero-aplicacao v-if="loadingShow"
                    :ApplicationData="dados"
                    :telaCheia="false"
                  />
                </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="8" md="12">
            <resposta-por-faixa-etaria-aplicacao v-if="loadingShow"
                :ApplicationData="dados"
              />
          </b-col>
          <b-col lg="4" md="12">
            <escala-respostas-finalizadas v-if="loadingShow"
                :ApplicationData="dados"
              />
          </b-col>
        </b-row>
        <b-row style="margin-right: 0 !important;margin-left: 0 !important;">
          <b-col lg="12" md="12" class="link-tela-cheia" >
              <div class="d-flex justify-content-center align-items-center mt-2">
                <img :src="link" alt="Tela Cheia">
                <div class="d-flex align-items-center ml-2">
                  <span>
                    <router-link
                      :to="{name: 'dashboard-tela-cheia-dass', params: {arquivoAnexo: arquivoAnexo}}"
                      target="_blank"
                      class="custom-link"
                    >Clique aqui</router-link>
                    e você será redirecionado para uma nova página com a dashboard em tela cheia.
                  </span>
                </div>
              </div>
          </b-col>
        </b-row>
      </div>

    </section>
  </b-overlay>
</template>

<script>
import EscalaRespostasAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasAplicacao.vue";
import EscalaRespostasPorGeneroAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasPorGeneroAplicacao.vue";
import RespostaPorFaixaEtariaAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/RespostaPorFaixaEtariaAplicacao.vue";
import EstatisticasDepressaoAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EstatisticasDepressaoAplicacao.vue";
import RespostasDeIndicacaoAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/RespostasDeIndicacaoAplicacao.vue";
import EscalaRespostasFinalizadas from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasFinalizadas.vue";
import { modalGenericFillableErrorWithReload } from '@/libs/sweetalerts'
import TituloGenerico from "@/views/pages/aplicacoes/visualizar-aplicacao/TituloGenerico.vue";
import link from '@/assets/custom-icons/send-link.svg'

import { BRow, BCol,BOverlay,BCard } from "bootstrap-vue";
export default {
  components: {
    EscalaRespostasAplicacao,
    EscalaRespostasPorGeneroAplicacao,
    RespostaPorFaixaEtariaAplicacao,
    EstatisticasDepressaoAplicacao,
    RespostasDeIndicacaoAplicacao,
    EscalaRespostasFinalizadas,
    BOverlay,
    TituloGenerico,
    BRow,
    BCol,
    BCard,
  },

  props: {
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      link: link,
      loadingShow: false,
      dados: {},
      arquivoAnexo: this.applicationData.link_qrcode.split(process.env.VUE_APP_APP_URL+'/cora-aplicacoes/')[1]
    }
  },

  mounted() {
    this.buscaDadosParaGraficoComArquivoAnexo()
  },

  methods: {
    buscaDadosParaGraficoComArquivoAnexo(){
      let params = {
          arquivoAnexo: this.arquivoAnexo
      }

      this.$http.get(this.$api.buscaAplicacaoPorArquivoAnexo, {params})
      .then((response) => {
        this.dados = response.data
        this.loadingShow = true;

      })
      .catch(() => {
        modalGenericFillableErrorWithReload(
          "Ocorreu um erro na dashboard. Tente novamente mais tarde"
        )
      })
    }
  },

}
</script>

<style scoped>
.custom-link, .custom-link:hover{
  color: #2772C0;
  text-decoration: underline;
}
.altura_linha_1_phq{
  height: 357px;
}
.altura_linha_2_phq_respostas_indicacao{
  height: 229.7px;
}
.altura_linha_2_phq_niveis_depressao{
  height: 229.7px;
}
.altura_linha_1_dass{
  height: 357px;
}
.grafico2{
  width: 100%;
  height: 200px;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}
.px-2{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.link-tela-cheia{
    background-color: #efefef;
    height: 87.36px;
}
.link{
  text-decoration: underline;
}

@media(min-width: 3000px) {
    .altura_linha_1_phq{
      height: 425px;
    }
    .altura_linha_1_dass{
      height: 445px;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .altura_linha_2_phq_niveis_depressao{
      height: 280.7px;
    }

    .altura_linha_2_phq_respostas_indicacao{
      height: 280.7px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .altura_linha_1_phq{
    height: 660px;
  }

  .altura_linha_2_phq_niveis_depressao{
    height: 325.7px;
  }

    .altura_linha_2_phq_respostas_indicacao{
      height: 325.7px;
    }

  .altura_linha_1_dass{
    height: 680px;
  }
}

@media (max-width: 992px){
  .altura_linha_2_phq_niveis_depressao{
    height: 560.7px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .altura_linha_1_phq{
    height: 688px;
  }

  .altura_linha_1_dass{
    height: 700px;
  }
}

@media (max-width: 767px) {
  .altura_linha_1_phq{
    height: 560px;
  }

  .altura_linha_1_dass{
    height: 610px;
  }
}

  @media (max-width: 450px) {
    .link-tela-cheia{
      height: 110.36px;
  }
}
</style>

