<template>
  <div>
    <div class="p-2 card">
      <b-overlay :show="loading" rounded="lg" opacity="0.7">
        <b-alert
          v-height-fade.appear
          :show="dismissCountDown"
          dismissible
          class="mb-0"
          variant="success"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <div class="alert-body">
            <span class="ml-25">Imagem copiada para área de transferência</span>
          </div>
        </b-alert>
        <b-row class="margin-personalizada">
          <b-col md="12" align="center">
            <div>
              <div class="container-custom-message cursor-pointer" @click="openLink(applicationData.link_qrcode)">
                <div class="c-button-personalizado">
                  <b-button
                      type="submit"
                      variant="custom"
                      class="b-personalizado"
                    >
                      <b-img  :src="require('@/assets/custom-icons/link.svg')" class="img-link" />
                    </b-button>
                </div>
                <div class="c-message-personalizado">
                  <span class="ml-25 align-middle">
                    <a href="#" class="custom-link">Clique aqui</a>
                    para acessar o questionário da aplicação, ou se preferir, leia o
                    <strong>QR Code</strong>
                    abaixo. Você pode também fazer o download.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        
        <div>
          <b-row>
            <b-col align="center">
            <div ref="qrcodeContainer">
              <qrcode-vue :value="applicationData.link_qrcode" :size="sizeQrCode" level="H"></qrcode-vue>
            </div>
            </b-col>
          </b-row>
          <b-row class="margin-personalizada">
            <b-col>
              <b-row class="d-flex justify-content-center">
                <b-button
                  type="submit"
                  variant="custom"
                  class="space-buttons width-buttons"
                  @click.prevent="copiarQrcode()"
                  :disabled="disableButtons"
                >
                  <div class="align-icons">
                    <feather-icon
                      icon="CopyIcon"
                      size="16"
                      class="icon-btn"
                    />
                    <span>Copiar QR code</span>
                  </div>
                </b-button>
              </b-row>

              <b-row class="d-flex justify-content-center">
                <b-button
                  type="submit"
                  variant="custom"
                  class="space-buttons width-buttons"
                  @click.prevent="baixarQrCodePdf()"
                  :disabled="disableButtons"
                >
                  <div class="align-icons">
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                      class="icon-btn"
                    />
                    <span>Versão para Impressão</span>
                  </div>
                </b-button>
              </b-row>

            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { VTree, VSelectTree } from "vue-tree-halower";
import "@core/scss/vue/libs/halower-tree.min.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import QrcodeVue from 'qrcode.vue';
import { getUserData } from "@/auth/utils";
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BImg,
    QrcodeVue,
    BRow,
    BBadge,
    BCol,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    VSelectTree,
    VTree,
    VuePerfectScrollbar,
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
  },

  props: {
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      sizeQrCode: 250,
      loading: false,
      disableButtons: false,
      dismissSecs: 3,
      dismissCountDown: 0,
    };
  },

  mounted() {
      this.gerarQrcode();
      this.getUsuarioLogado();
      this.verificaStatusAplicacao();
  },

  methods: {

    async baixarQrCodePdf() {
      this.loading = true;
      let rota = this.$helpers.preparaRotaComParametros( this.$api.exportarPdf, "{idAplicacao}", this.applicationData.id_aplicacao );
      await this.$http.get(rota, {responseType: "blob"}).then(response => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = 'Cora - '+this.applicationData.nome_aplicacao+'.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      });
      this.loading = false;
    },
 
    async copiarQrcode() {
      const qrCodeContainer = this.$refs.qrcodeContainer;
      if (qrCodeContainer) {
        const qrCodeCanvas = qrCodeContainer.querySelector('canvas');
        if (qrCodeCanvas) {
          qrCodeCanvas.toBlob((blob) => {
            const clipboardItem = new ClipboardItem({ 'image/png': blob });
            navigator.clipboard.write([clipboardItem]);
            this.dismissCountDown = this.dismissSecs;
          });
        }
      }
    },

    verificaStatusAplicacao(){
      if(
        this.applicationData.situacao_aplicacao == 'Em pausa'   ||
        this.applicationData.situacao_aplicacao == 'Finalizado' ||
        this.applicationData.situacao_aplicacao == 'Cancelado' 
      ){
        this.disableButtons = true;
      }else{
        this.disableButtons = false;
      }
    },

    getUsuarioLogado(){
      const userData = getUserData().role.perfil;
      this.usuarioLogado = userData[0].perfil.descricao;
    },

    gerarQrcode(){
      this.loading = true;
      this.valueQrCode = this.applicationData.link_qrcode;
      this.loading = false;
    },

    openLink(url){
      window.open(url, '_blank');
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-link{
    color: #2772C0;
    text-decoration: underline;
    font-weight: 600;
  }

  .container-custom-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 15px;

    height: auto;
    width: auto;
    max-height: 13vh;
    max-width: 32vw;

    background-color: #EFEFEC;
    border-radius: 15px;

    @media (max-width: 509px){
      flex-direction: column;
      justify-content: center;
      padding-top: 2vh;
      padding-bottom: 5vh;
      min-height: 32vh;
      max-width: 45vw;
    }

    @media (min-width: 510px) and (max-width: 970px){
      max-width: 50vw;
    }
  }

  .c-button-personalizado{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    max-height: 12vh;

    @media (max-width: 509px){
      text-align: center;
      height: auto;
      width: 90%;
    }

    @media (min-width: 510px) and (max-width: 970px){
      align-items: center;
      height: 90%;
      width: 25%;
    }
  }

  .b-personalizado{
    cursor: default !important;
    width: 100%;
    height: auto;
    margin: 30%;
    background-color: #1572CD;
    border: none;
    opacity: 1;
  }

  .img-link{
    width:100%;
    min-width:30px;

    @media (max-width: 509px){
      min-width: 30px;
      min-height: auto;
    }

    @media (min-width: 510px) and (max-width: 970px){
      min-width: 30px;
      min-height: auto;
    }
  }

  .c-message-personalizado{
    align-items: center !important;
    display: flex !important;
    text-align: left !important;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 3%;
    max-height: 12vh;
    max-width: 100%;
    font-size: 16px;
    font-weight: 400;
    
    @media (max-width: 509px){
      text-align: center !important;
      font-size: 13px;
    }

    @media (min-width: 510px) and (max-width: 1320px){
      font-size: 12px;
    }
  }

  .margin-personalizada{
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .align-icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .icon-btn{
    margin-right: 0.625rem;
  }

  .space-buttons{
    margin-bottom: 10px;
  }

  .width-buttons{
    width: 250px;
  }

  .bg-black{
    background: black;
  }
</style>