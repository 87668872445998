<template>
  <b-button
    class="customButton"
    type="button"
    variant="outline-primary"
    @click="$emit('buttonClickAction')"
  >
    <span class="espacamentoIconeBotao">
      <feather-icon
        :icon="iconName"
      />
    </span>
    <span>
      {{ buttonDescription }}
    </span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  },

  props: {
    buttonDescription: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
  }
}
</script>
<style scoped>
  .espacamentoIconeBotao{
    margin-left: -5px;
    margin-right: 5px;
  }

  .customButton{
    font-size: 14px;
  }

  @media (max-width: 1400px) {
    .espacamentoIconeBotao{
      margin-left: 0;
      margin-right: 2px;
    }
    .customButton{
      padding: 8px !important;
      font-size: 12px;
    }
  }
</style>
