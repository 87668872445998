<template>
  <b-overlay
    :show="loadingShow"
    rounded="lg"
    opacity="0.6"
  >
    <div class="bg-white">
      <div
        id="page-detail"
        class="shadow"
      >
        <div
          class="background"
          :style="`background: url(${background}); background-size: cover;`"
        >
          <div class="avatarModelSpacing">
            <div class="avatarModel">
              {{ applicationData ? applicationData.razao_social_empresa.charAt(0) : '-' }}
            </div>
          </div>

          <div class="spacingCompanyName">
            <h1 class="companyName text-white">
              {{ applicationData ? applicationData.razao_social_empresa : '-' }}
            </h1>
          </div>
        </div>

        <div class="tabs">
          <b-nav tabs>
            <b-nav-item
              v-for="(tab, index) in tabs"
              :key="index"
              :active="tab.component == currentTab"
              @click="setTabClick(tab.component)"
            >
              {{ tab.name }}
            </b-nav-item>
          </b-nav>
        </div>
      </div>

      <div class="mx-2 mt-1">
        <TimedAlert
          v-if="applicationSituation && applicationSituation === applicationStatus.emPausa"
          class="custom-margin-bottom"
          variant="primary"
          boldMessage="Aplicação pausada. "
          message='Para retomá-la, clique no botão "Pausar ou cancelar aplicação".'
        />
        <TimedAlert
          v-if="applicationSituation && applicationSituation === applicationStatus.cancelado"
          class="custom-margin-bottom"
          variant="danger"
          boldMessage="Aplicação cancelada. "
          message="Não é possível retornar essa aplicação."
        />
      </div>

      <div
        v-if="!loadingShow && applicationData"
        :class="{'px-2': getCurrentTabObject().name != 'Dashboard', 'pt-2':  getCurrentTabObject().name != 'Dashboard' }"
      >
        <component
          :is="currentTabComponent"
          :subAccess="currentTabComponentSubAccess"
          :applicationData="applicationData"
          @changeSubAccess="changeSubAccess"
          @reloadInformation="loadApplication"
        />
      </div>
    </div>
    <div class="mt-1 p-2 card" v-if="currentTab != 'TabDashboard'">
      <b-col>
        <b-row
          cols="1"
        >
          <b-col>
            <b-button
              type="submit"
              variant="custom"
              @click.prevent="returnToList()"
            >
              <span>Voltar para Aplicações</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </div>
  </b-overlay>
</template>

<script>

import {
  BCard,
  BCol,
  BNav,
  BNavItem,
  BOverlay,
  BRow,
  BButton
} from 'bootstrap-vue'
import { modalGenericFillableErrorWithReload, modalGenericFillableErrorWithRedirectHome } from '@/libs/sweetalerts'
import TabDadosAplicacao from './tab-components/DadosAplicacao.vue'
import TabDashboard from './tab-components/Dashboard.vue'
import TabEnviarEmail from './tab-components/EnviarEmail.vue'
import TabExportarRespostas from './tab-components/ExportarRespostas.vue'
import TabLinkQRCode from './tab-components/LinkQRCode.vue'
import moment from 'moment'
import $aplicacaoState from '@/store/aplicacaoState';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import { $tabComponent, $subAccess, $typeStatus, $typeQuestionnaire } from "./tab-components/dados-aplicacao-components/accessEnum.js";

export default {
  components: {
    BCard,
    BNav,
    BNavItem,
    BOverlay,
    BRow,
    BCol,
    TabDadosAplicacao,
    TabDashboard,
    TabEnviarEmail,
    TabExportarRespostas,
    TabLinkQRCode,
    TimedAlert,
    BButton
  },

  computed: {
    currentTabComponent() {
      return this.currentTab;
    },
    currentTabComponentSubAccess() {
      return this.getCurrentTabObject().subAccess;
    },
  },

  data() {
    return {
      background: require('@/assets/images/backgrounds/grupo-detalhe.svg'),
      applicationData: null,
      currentTab: 'TabDadosAplicacao',
      loadingShow: true,
      loadingComponent: true,
      tabs: null,
      applicationStatus: $typeStatus,
      applicationSituation: null
    }
  },

  async mounted() {
    await this.initiateVisualization();
  },

  methods: {
    async initiateVisualization() {
      if(
          ($aplicacaoState.getters.getIdAplicacao() === null || typeof $aplicacaoState.getters.getIdAplicacao() === 'undefined') &&
          (typeof this.$route.params.idAplicacao === "undefined" || this.$route.params.idAplicacao === null)
      ) {
        await modalGenericFillableErrorWithRedirectHome(
          'Erro ao acessar a URL'
        )
      }else{
        await this.setIdAplicacaoVuex();
        await this.loadApplication();
      }
    },

    setIdAplicacaoVuex() {
      if(
          (typeof this.$route.params.idAplicacao !== "undefined" && this.$route.params.idAplicacao !== null) &&
          (this.$route.params.idAplicacao !== $aplicacaoState.getters.getIdAplicacao())
        ){
          $aplicacaoState.actions.setIdAplicacao(this.$route.params.idAplicacao);
        }
    },

    async loadApplication(firstAccess = true){
      this.loadingShow = true
      await this.$http.get(this.$api.cora.aplicacaoShowAndEdit($aplicacaoState.getters.getIdAplicacao()))
      .then(res => {
        this.applicationData = res.data;
        this.prepareData();
        if(firstAccess){
          this.firstAccess();
        }
      }).catch(async () => {
        await modalGenericFillableErrorWithReload(
          'Não foi possível acessar a aplicação solicitada'
        )
      })
      this.loadingShow = false
    },

    firstAccess(){
      this.currentTab = this.$route.params.tabComponent ? this.$route.params.tabComponent : $tabComponent.dadosAplicacao;
      if(this.$route.params.subAccess){
        this.changeSubAccess(this.$route.params.subAccess)
      }
    },

    changeSubAccess(subAccess){
      this.getCurrentTabObject().subAccess = subAccess;
    },

    prepareData(){
      this.applicationSituation = this.applicationData.situacao_aplicacao;
      this.tabs = [
        {
          name: 'Dados da Aplicação',
          component: $tabComponent.dadosAplicacao,
          subAccess: $subAccess.visualizar
        },
        {
          name: 'Link e QR Code',
          component: $tabComponent.linkQRCode,
          subAccess: null
        },
        {
          name: 'Enviar por e-mail',
          component: $tabComponent.enviarEmail,
          subAccess: null
        }
      ]
      if(this.applicationData.tipo_questionario === $typeQuestionnaire.padronizado){
        this.tabs.push(
          {
            name: 'Dashboard',
            component: $tabComponent.dashboard,
            subAccess: null
          },
          {
            name: 'Exportar respostas',
            component: $tabComponent.exportarRespostas,
            subAccess: null
          }
        )
      }

      this.prepareDateFormat()
    },

    prepareDateFormat(){
      this.applicationData.data_inicio_aplicacao = moment(this.applicationData.data_inicio_aplicacao, "YYYY-MM-DD").format('DD/MM/YYYY');
      this.applicationData.data_termino_aplicacao = moment(this.applicationData.data_termino_aplicacao, "YYYY-MM-DD").format('DD/MM/YYYY');
      this.applicationData.horario_inicio_aplicacao = moment(this.applicationData.horario_inicio_aplicacao, "HH:mm:ss").format('HH:mm:ss');
      this.applicationData.horario_termino_aplicacao = moment(this.applicationData.horario_termino_aplicacao, "HH:mm:ss").format('HH:mm:ss');
    },

    setTabClick(component){
      this.currentTab = component;
    },

    getCurrentTabObject(){
      return this.tabs.find(tab => tab.component === this.currentTab);
    },

    returnToList(){
      let id_perfil = this.$store.state.sessions.userData.role.perfil[0].id_perfil;
      if(id_perfil < 4){
        this.$router.push({
          name: 'listagem-aplicacoes'
        });
      }else{
        this.$router.push({
          name: 'minhas-aplicacoes-cora'
        });
      }
    },
  }
}
</script>
<style scoped>
  .background {
    height: 136px;
    width: 100%;
  }

    .spacingCompanyName {
      position: relative;
      margin-left: 180px;
      margin-right: 10px;
      height: 100%;
      display: flex;
      align-items: end;
    }

    .companyName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 20px;
    }

    .avatarModelSpacing {
      position: absolute;
      top: 40px;
      left: 30px;
      height: 125px;
      width: 125px;
      border-radius: 5%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .avatarModel {
        height: 95%;
        width: 95%;
        background-color: #002060;
        border-radius: 5%;
        font-size: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

  .tabs {
    background-color: #fff;
    padding: 10px 0;
    border-bottom-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;
  }
    .nav.nav-tabs {
      margin: 0;
      padding: 0;
      justify-content: center;
    }
      .nav-link {
        background: #fff;
        color: #5e5873;
      }
      .nav-link.active {
        background: #7030A0;
        border-color: #7030A0;
        color: #fff;
        border-radius: 0.358rem;
      }
      .nav-link:after {
        display: none;
      }

  .custom-margin-bottom {
    margin-bottom: -5px;
  }

  @media (max-width: 1450px) {

    .spacingCompanyName {
      margin-left: 160px;
    }

    .companyName {
      margin-bottom: 20px;
    }
    .avatarModelSpacing {
      top: 15px;
      height: 110px;
      width: 110px;
    }
      .avatarModel {
        font-size: 70px;
      }
  }

  @media (max-width: 768px) {
    .avatarModelSpacing {
      top: 35px;
      height: 90px;
      width: 90px;
    }
      .avatarModel {
        font-size:60px;
      }
    .spacingCompanyName {
      margin-left: 140px;
    }

    .companyName {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 450px) {
    .avatarModelSpacing {
      top: 20px;
      height: 100px;
      width: 80px;
    }
      .avatarModel {
        font-size: 40px;
      }

    .spacingCompanyName {
      margin-left: 130px;
    }

    .companyName {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }
</style>
