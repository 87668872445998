<template>
  <b-row
    cols="1"
    class="page"
  >
    <b-col>
      <b-row
        cols="1"
        cols-sm="2"
        class="mb-2"
      >
        <b-col>
          <div class="pageTitle">
            {{ applicationData.nome_aplicacao }}
          </div>
          <div class="pageSubText">
            {{ applicationData.descricao_aplicacao }}
          </div>
        </b-col>
        <b-col
          class="d-lg-inline-flex justify-content-lg-end mb-n1 text-center"
        >
          <div
            v-if="
              $can(actionRule.atualizar, entityRule.moduloCora.aplicacaoStatus) &&
              applicationData.situacao_aplicacao !== applicationStatus.cancelado &&
              applicationData.situacao_aplicacao !== applicationStatus.finalizado &&
              applicationData.id_criador === idUsuarioLogado
            "
            class="custom-my-1"
          >
            <button-generic-with-feather-icon
              buttonDescription="Pausar ou cancelar aplicação"
              :iconName="'PauseCircleIcon'"
              @buttonClickAction="callPauseOrCancelApplication"
            />
          </div>
          <div
            v-if="
                $can(actionRule.atualizar, entityRule.moduloCora.aplicacao) &&
                applicationData.situacao_aplicacao !== applicationStatus.cancelado &&
                applicationData.situacao_aplicacao !== applicationStatus.finalizado &&
                applicationData.id_criador === idUsuarioLogado
            "
            class="ml-lg-1 mt-1-lg-down"
          >
            <button-generic-with-feather-icon
              :buttonDescription="'Editar aplicação'"
              :iconName="'Edit3Icon'"
              @buttonClickAction="callEditApplication"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col>
      <b-row
        cols="1"
        cols-sm="2"
        class="mb-2"
      >
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Nome da Aplicação
          </div>
          <div class="pageText">
            {{ applicationData.nome_aplicacao }}
          </div>
        </b-col>
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Grupo
          </div>
          <div class="pageText">
            {{ applicationData.descricao_grupo_empresa }}
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col>
      <b-row
        cols="1"
        cols-sm="2"
        class="mb-2"
      >
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Empresa
          </div>
          <div class="pageText">
            {{ applicationData.razao_social_empresa }}
          </div>
        </b-col>
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Questionário
          </div>
          <div class="pageText">
            {{ applicationData.descricao_questionario }}
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col>
      <b-row
        cols="1"
        cols-sm="2"
        cols-md="4"
        class="mb-1"
      >
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Data de início
          </div>
          <div class="pageText">
            {{ applicationData.data_inicio_aplicacao }}
          </div>
        </b-col>
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Data de término
          </div>
          <div class="pageText">
            {{ applicationData.data_termino_aplicacao }}
          </div>
        </b-col>
        <b-col class="mb-1">
          <div class="pageSubtitle">
            Horário de início
          </div>
          <div class="pageText">
            {{ applicationData.horario_inicio_aplicacao }}
          </div>
        </b-col>
        <b-col>
          <div class="pageSubtitle">
            Horário de término
          </div>
          <div class="pageText">
            {{ applicationData.horario_termino_aplicacao }}
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="mb-2">
      <b-row
        cols="1"
        cols-sm="2"
      >
        <b-col>
          <div class="pageSubtitle">
            Descrição
          </div>
          <div class="pageText">
            {{ applicationData.descricao_aplicacao }}
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="customQuestions.length > 0"
      class="mb-2"
    >
      <app-collapse>
        <app-collapse-item
          v-for="(question, key) in customQuestions"
          :key="key"
          :title="question.enunciation"
          class="customMarginBottom border rounded"
        >
          <div
            v-for="(option, optionKey) in question.options"
            :key="optionKey"
            :class="question.options.length != optionKey+1 ? 'customBorder' : null"
          >
            {{ option.alternative }}
          </div>
        </app-collapse-item>
      </app-collapse>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import ButtonGenericWithFeatherIcon from '@/views/components/button/ButtonGenericWithFeatherIcon.vue'
import { $regraEntidade, $regraAcao } from "@/custom-enum/regras-enum.js";
import { $typeStatus, $subAccess } from "./accessEnum.js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    ButtonGenericWithFeatherIcon,
    AppCollapseItem,
    AppCollapse
  },

  props: {
    applicationData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      entityRule: $regraEntidade,
      actionRule: $regraAcao,
      applicationStatus: $typeStatus,
      idUsuarioLogado: this.$store.state.sessions.userData.usuario.id_usuario,
      customQuestions: []
    }
  },

  mounted(){
    this.prepareCustomQuestions(this.$props.applicationData.aplicacao_questao)
  },

  methods: {
    callEditApplication(){
      this.$emit('changeComponent', $subAccess.editar)
    },
    callPauseOrCancelApplication(){
      this.$router.push(
        {
          name: 'visualizar-aplicacao-pausar-cancelar',
          params: { applicationData: this.$props.applicationData }
        }
      );
    },
    returnToList(){
      let id_perfil = this.$store.state.sessions.userData.role.perfil[0].id_perfil;
      if(id_perfil < 4){
        this.$router.push({
          name: 'listagem-aplicacoes'
        });
      }else{
        this.$router.push({
          name: 'minhas-aplicacoes-cora'
        });
      }
    },
    prepareCustomQuestions(aplicacaoQuestao) {
      if(aplicacaoQuestao && aplicacaoQuestao.length > 0){
        this.customQuestions = []
        for (let i = 0; i < aplicacaoQuestao.length; i++) {
          this.customQuestions.push(
          {
            enunciation: null,
            options: []
          })
          this.customQuestions[i].enunciation = aplicacaoQuestao[i].questao[0].conteudo
          for (let f = 0; f < aplicacaoQuestao[i].questao[0].opcoes.length; f++) {
            this.customQuestions[i].options.push(
              {
                alternative: null
              }
            )
            this.customQuestions[i].options[f].alternative = aplicacaoQuestao[i].questao[0].opcoes[f].conteudo
          }
        }
      }
    }
  },
}
</script>
<style scoped>
  .page{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    word-wrap: break-word;
  }
  .pageTitle{
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #5E5873;
  }
  .pageSubtitle{
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #5E5873;
  }
  .pageText{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5E5873;
  }
  .pageSubText{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5E5873;
  }
  .customMarginBottom{
    margin-bottom: 5px;
  }

  .customBorder{
    border-bottom: 2px solid #ebe9f1;
    margin-bottom: 5px;
  }

  @media (max-width: 991.98px) {
    .mt-1-lg-down {
        margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    .custom-my-1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 575px) {
    .page{
      text-align: center;
    }
  }
</style>
